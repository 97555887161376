import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = false

Vue.filter('formatCompletion', function (completion) {
    return completion ? `${completion.toFixed(2)}%` : '??%'
})

new Vue({
    vuetify,
    render: h => h(App),
}).$mount('#app')
