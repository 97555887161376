<template>
  <div class="main-page__container">
    <div
        class="mini-images"
    >
      <template
          v-for="(snapshot, index) in snapshotList"
      >
        <div class="mini-images__img-wrapper"
             :class="{
               selected: selectedImageIndex === index,
             }"
             :key="snapshot.time"
             v-if="!errors[index]"
             @click="onImageClick(index)"
        >
          <img
              class="mini-images__img"
              :src="`api/snapshot_${snapshot.time}.png`"
              @error="onImageError(index)"
          />
          <div class="mini-images__completion">
            {{ snapshot.completion | formatCompletion }}
          </div>
        </div>
      </template>
    </div>
    <div
        class="selected-image__wrapper"
    >
      <v-btn block
             @click="onUpClick()"
             ref="upBtn"
      >
        &#x21E7;
      </v-btn>
      <img class="selected-image__img"
           v-if="selectedImageSrc"
           :src="selectedImageSrc"/>
      <v-btn block
             @click="onDownClick()"
             ref="downBtn"
      >
        &#x21E9;
      </v-btn>
    </div>
  </div>
</template>

<script>

import {actions, getters} from "@/stores/MainStore"

export default {
  name: 'MainPage',
  components: {},
  mounted: function () {
    window.addEventListener('keyup', ({code}) => {
      if (code === 'ArrowUp') {
        this.onUpClick()
        this.rippleBtn(this.$refs.upBtn.$el)
      } else if (code === 'ArrowDown') {
        this.onDownClick()
        this.rippleBtn(this.$refs.downBtn.$el)
      }
    })
  },
  data: () => ({
    errors: {},
  }),
  computed: {
    selectedImageIndex: getters.selectedImageIndex,
    selectedImageSrc: getters.selectedImageSrc,
    selectedImageCompletion: getters.selectedImageCompletion,
    snapshotList: getters.snapshotList,
    progress: getters.progress,
  },
  methods: {
    ...actions,
    onImageError: function (index) {
      this.errors[index] = true
      console.error(`Error when loading ${index}`)
    },
    onImageClick: function (index) {
      this.selectImage(index)
    },
    onUpClick: function () {
      this.decrementSelectedImageIndex()
    },
    onDownClick: function () {
      this.incrementSelectedImageIndex()
    },
    rippleBtn: function($el) {
      let ev = new Event("mousedown")
      let offset = $el.getBoundingClientRect()
      ev.clientX = offset.left + offset.width/2
      ev.clientY = offset.top + offset.height/2
      $el.dispatchEvent(ev)

      setTimeout(function() {
        $el.dispatchEvent(new Event("mouseup"))
      }, 100)
    }
  }
}
</script>
<style lang="scss">
.main-page {
  &__container {
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    height: 1px
  }
}

.mini-images {
  flex: 1 1 10%;
  min-width: 10%;
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6a6a6a;
  }

  &__img-wrapper {
    position: relative;
    box-sizing: border-box;

    &.selected {
      outline: solid 3px red;
      outline-offset: -3px;
    }
  }

  &__img {
    display: block;
    width: 100%;
  }

  &__completion {
    position: absolute;
    top: 5px;
    right: 5px;
    color: white;
    text-shadow: 1px 1px 2px black;
    user-select: none;

    @media (max-width: 900px) {
      display: none;
    }
  }
}

.selected-image {
  &__wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 90%;
  }

  &__img {
    display: block;
    flex: 1 1 auto;
    max-height: 100%;
    max-width: 100%;
  }

  &__completion {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    text-shadow: 1px 1px 2px black;
    user-select: none;
  }

}
</style>
