<template>
  <v-app>
    <v-app-bar app
               color="orange"
    >
      <v-toolbar-title>
        {{ jobName }} ({{ printerState }} {{ formattedProgress }})
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-title>
        {{ timesSec }}
      </v-toolbar-title>
      <v-btn icon
             :loading="loading"
             @click="updateListData()"
      >
        <v-icon>mdi-refresh</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main style="height: 100vh; max-height: 100%;">
      <v-progress-linear
          color="red lighten-2"
          :value="progress"
          height="8px"
          stream
      ></v-progress-linear>
      <MainPage/>
    </v-main>
  </v-app>
</template>
<style lang="scss">
.v-main {
  height: 1px !important;

  &__wrap {
    display: flex;
    flex-direction: column;
  }
}
</style>
<script>
import MainPage from './components/MainPage'
import {actions, getters} from "@/stores/MainStore"

export default {
  name: 'App',
  mounted: function () {
    setInterval(function () {
      if (!this.loading) {
        if (!this.timerMills) {
          this.updateListData()
        }
        this.decrementTimer()
      }
    }.bind(this), 1000)
  },
  computed: {
    jobName: getters.jobName,
    progress: getters.progress,
    printerState: getters.printerState,
    formattedProgress: getters.formattedProgress,
    timerMills: getters.timerMills,
    loading: getters.loading,
    timesSec: function () {
      return this.timerMills / 1000
    },
  },
  components: {
    MainPage,
  },
  methods: {
    ...actions
  },
  data: () => ({}),
}
</script>
<style lang="scss">
html, body {
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
