import axios from 'axios'
import Vue from "vue"

export const UPDATE_INTERVAL = 3000
export const UNKNOWN = 'Unknown'

const state = Vue.observable({
    selectedImageIndex: 0,
    lastUpdate: null,
    snapshotList: null,
    lastJsonData: null,
    timerMills: 0,
    loading: false,
})
export const getters = {
    selectedImageSrc: function () {
        const fileName = getters.imageFileNameByIndex(getters.selectedImageIndex())
        return fileName ?
            `api/snapshot_${fileName}` :
            null
    },
    selectedImageCompletion: function () {
        let completion = null
        const snapshot = getters.getSnapshotByIndex(getters.selectedImageIndex())
        if (snapshot?.completion) {
            completion = snapshot.completion
        }
        return completion
    },
    selectedImageIndex: function () {
        return state.selectedImageIndex
    },
    snapshotList: function () {
        return state.snapshotList
    },
    lastUpdate: function () {
        return state.lastUpdate
    },
    lastJsonData: function () {
        return state.lastJsonData
    },
    jobName: function () {
        return state.lastJsonData?.job?.file?.name || 'Unknown'
    },
    progress: function () {
        return state.lastJsonData?.progress?.completion || 0
    },
    formattedProgress: function () {
        return getters.progress().toFixed(2) + '%'
    },
    printerState: function () {
        return state.lastJsonData?.state || UNKNOWN
    },
    timerMills: function () {
        return state.timerMills
    },
    loading: function () {
        return state.loading
    },
    imageFileNameByIndex: function (index) {
        let fileName = null
        const snapshot = getters.getSnapshotByIndex(index)
        if (snapshot?.time) {
            fileName = `${snapshot.time}.png`
        }
        return fileName
    },
    getSnapshotByIndex: (index) => {
        let snapshot = null
        if (state.snapshotList?.length > index) {
            snapshot = state.snapshotList[index]
        }
        return snapshot
    }
}
export const mutations = {
    setSelectedImageIndex: (selectedImageIndex) => state.selectedImageIndex = selectedImageIndex,
    setLastUpdate: (lastUpdate) => state.lastUpdate = lastUpdate,
    setSnapshotList: (snapshotList) => state.snapshotList = snapshotList.reverse(), // NEWEST AT TOP
    setLastJsonData: (lastJsonData) => state.lastJsonData = lastJsonData,
    setLoading: (loading) => state.loading = loading,
    decrementTimer: () =>
        state.timerMills = Math.max(state.timerMills - 1000, 0),
    setTimer: (timerMills) => state.timerMills = timerMills,
}
export const actions = {
    updateListData: function () {
        axios.get(`list.json?random=${Math.random()}`)
            .then(x => {
                const lastUpdate = x.data.last_update
                const snapshotList = x.data.snapshot_list

                mutations.setSnapshotList(snapshotList)
                if (lastUpdate !== getters.lastUpdate()) {
                    console.log('(DEBUG) update printer info')
                    actions.updateLastJsonData(`${x.data.last_update}`)
                    mutations.setLastUpdate(x.data.last_update)
                }
            })
            .catch(err => console.error(err))
            .finally(() => {
                mutations.setLoading(false)
                mutations.setTimer(UPDATE_INTERVAL)
            })
    },
    updateLastJsonData: function (name) {
        mutations.setLoading(true)
        mutations.setTimer(0)
        axios
            .get(`api/snapshot_${name}.json`)
            .then(x => mutations.setLastJsonData(x.data))
            .catch(err => console.error(err))
            .finally(() => mutations.setLoading(false))
    },
    selectImage: function (index) {
        mutations.setSelectedImageIndex(index)
    },
    incrementSelectedImageIndex: function () {
        let index = getters.selectedImageIndex()
        mutations.setSelectedImageIndex(Math.min(index + 1, getters.snapshotList().length - 1))
    },
    decrementSelectedImageIndex: function () {
        let index = getters.selectedImageIndex()
        mutations.setSelectedImageIndex(Math.max(index - 1, 0))
    },
    updateJsonDataByImage: function (index) {
        const fileName = getters.snapshotList()[index]
        const url = `api/snapshot_${fileName}.png`
        mutations.setLoading(true)
        mutations.setTimer(0)
        axios
            .get(url)
            .catch(err => console.error(err))
            .finally(() => mutations.setLoading(false))
    },
    decrementTimer: function () {
        if (getters.timerMills() > 0) {
            mutations.decrementTimer()
        }
    }
}
